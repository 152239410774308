<template>
    <div class='fadeInRight'>
        <el-form :model="offlinePayForm" :rules="rules" ref="offlinePayForm" label-width="100px" style='border:1px solid skyblue;padding-top:50px'>
            <el-form-item label="付款方式" prop="payType" required>
                <el-select v-model="offlinePayForm.payType" placeholder="请选择付款方式">
                <el-option label="支付宝" value="2"></el-option>
                <el-option label="银行卡" value="3"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="充值金额" prop="money" required>
                <el-input v-model="offlinePayForm.money" style='width:220px'></el-input>
            </el-form-item>
            <el-form-item label="上传凭证" required>
                <input ref="fileInput" type="file" class="form-control add_file_inter" @change="changeFile()" name="file">
            </el-form-item>
            <el-form-item label="账号" prop="accountNumber" required>
                <el-input v-model="offlinePayForm.accountNumber" style='width:220px'></el-input>
            </el-form-item>
            <el-form-item label="转账单号" prop="transactionId" required>
                <el-input v-model="offlinePayForm.transactionId" style='width:220px'></el-input>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
                <el-input type="textarea" v-model="offlinePayForm.remark" style="width:300px"></el-input>
            </el-form-item>

            <el-form-item size="large">
                <el-button type="primary" @click="onSubmit">提交审核</el-button>
            </el-form-item>
            
        </el-form>

    </div>
</template>
<script>
import {uploadPayOfflineUrl,offlineTransfer} from "@/api/agentManagement.js"
export default {
    data(){
        return {
            offlinePayForm:{
                payType:'',
                money:'',
                proofUrl:'',
                accountNumber:'',
                transactionId:'',
                remark:'',
            },
            rules:{
               payType:[{required: true,message: '请选择支付方式',trigger: 'blur'}],
               money:[{required: true,message: '请填写金额',trigger: 'blur'}],
               accountNumber:[{required: true,message: '请填写账号',trigger: 'blur'}],
               transactionId:[{required: true,message: '请填写转账单号',trigger: 'blur'}],
            }
        }
    },
    methods: {
        changeFile(){
            let formData = new FormData()
            formData.append('file',this.$refs['fileInput'].files[0])
            uploadPayOfflineUrl(formData).then(res=>{
						this.offlinePayForm.proofUrl = res.url		
            })
        },
        onSubmit(){
            this.$refs["offlinePayForm"].validate((validate) => {
					if (validate) {
                        if(!this.offlinePayForm.proofUrl){
                            this.$alert('请上传凭证', '提示', {
                                confirmButtonText: '确定',
                            });
                            return
                        }
						offlineTransfer(this.offlinePayForm).then(res=>{
							this.$message({
								type: 'success',
								message:'提交审核成功'
							});
						})
				    }
			})
        }
    },
}
</script>

<style>
    
</style>